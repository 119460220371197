.gift-wrapping-container {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;
  display: flex;
  height: 100vh;
  justify-content: center;
  opacity: 1;
  position: relative;
  transition: all 0.5s ease;
  z-index: 1;

  .overlay {
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .videoTag {
    float: left;
    height: 100%;
    left: 0;
    object-fit: cover;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all 0.5s ease;
    width: 100%;
    z-index: -1;

    &.active {
      opacity: 1;
    }
  }

  &.changing {
    opacity: 0;
    transition: all 0.5s ease;
  }

  &.first-step {
    background-image: url('../assets/images/giftWrapping/first-step-bg.jpg');

    .title {
      background-image: url('../assets/images/giftWrapping/step-one-title.svg');

      @include responsiveBetween('sm', 'xxl') {
        height: 105px;
      }

      @include mac13() {
        height: 105px;
      }
    }
  }

  &.second-step {
    background-image: url('../assets/images/giftWrapping/second-step-bg.jpg');

    .title {
      background-image: url('../assets/images/giftWrapping/step-two-title.svg');

      @include responsiveBetween('sm', 'xxl') {
        height: 125px;
      }

      @include mac13() {
        height: 125px;
      }
    }
  }

  &.third-step {
    background-image: url('../assets/images/giftWrapping/third-step-bg.jpg');
  }

  &.last-step {
    background-image: url('../assets/images/giftWrapping/last-step-bg.jpg');

    .content {
      margin-top: -18%;

      @include responsiveBetween('sm', 'xxl') {
        margin-top: -20%;
      }

      @include mac13() {
        margin-top: -20%;
      }
    }

    .video-content p {
      font-size: 28px;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;

      @include responsiveBetween('sm', 'xxl') {
        font-size: 18px;
      }

      @include mac13() {
        font-size: 18px;
      }
    }

    .receive-gift {
      background-color: $color-white;
      border: none;
      border-radius: 100px;
      color: $color-black;
      font-size: 28px;
      font-weight: 700;
      padding: 8px 0;
      text-decoration: none;
      text-transform: uppercase;
      width: 55%;

      @include responsiveBetween('sm', 'xxl') {
        font-size: 18px;
        width: 45%;
      }

      @include mac13() {
        font-size: 18px;
        width: 45%;
      }
    }
  }

  .title {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 130px;
    width: 100%;
  }

  .item {
    align-items: center;
    display: flex;
    font-size: 44px;
    font-weight: 700;
    justify-content: center;
    line-height: 60px;
    margin-bottom: 45px;
    position: relative;

    @include responsiveBetween('sm', 'xxl') {
      font-size: 28px;
      line-height: 36px;
    }

    @include mac13() {
      font-size: 28px;
      line-height: 36px;
    }
  }

  .spot {
    background-size: cover;
    display: inline-block;
    height: 80px;
    margin-right: 15px;
    width: 80px;
    background-repeat: no-repeat;

    &.one {
      background-image: url('../assets/images/giftWrapping/spot_1.svg');
    }
    &.two {
      background-image: url('../assets/images/giftWrapping/spot_2.svg');
    }
    &.three {
      background-image: url('../assets/images/giftWrapping/spot_3.svg');
    }

    @include responsiveBetween('sm', 'xxl') {
      height: 50px;
      width: 50px;
    }

    @include mac13() {
      height: 50px;
      width: 50px;
    }
  }

  .video-content {
    z-index: 2;
  }

  .video-container {
    height: 100vh;
    overflow: hidden;
    position: absolute;
    width: 100%;
    video {
      height: 100vh;
      object-fit: fill;
      width: 100%;

      @include responsiveBetween('xs', 'lg') {
        display: none;
      }
    }
  }

  .content {
    text-align: center;

    p {
      font-size: 44px;
      font-weight: 700;
      line-height: 60px;

      @include responsiveBetween('sm', 'xxl') {
        font-size: 28px;
        line-height: 36px;
      }

      @include mac13() {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
}
