.wanderer-background {
  background-image: url('../../assets/images/boxes/wanderer/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 750px;
  transition: all 1.8s;
  &.open {
    background-size: 110% 110%;
  }

  .wanderer-title {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 1.8s;
    width: 800px;
    z-index: 99;

    @include mac13() {
      width: 550px;
    }

    @include mac15() {
      width: 650px;
    }

    &.go-down {
      transform: translateY(60%);
    }
  }

  .open-box {
    background-color: $color-wanderer-light-blue;
    border-radius: 25px;
    color: #0000cb;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    left: 0;
    margin: auto;
    padding: 10px 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 210px;
    width: 184px;

    @include mac13() {
      font-size: 16px;
      top: 20%;
    }

    @include mac15() {
      top: 150px;
    }
  }

  .close-box {
    background-color: $color-wanderer-light-blue;
    border-radius: 25px;
    color: #0000cb;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    margin: auto;
    opacity: 0;
    padding: 10px 0;
    position: absolute;
    right: 40px;
    text-align: center;
    top: 1%;
    transform: translateY(-80px);
    transition: all 1.8s;
    width: 184px;
    z-index: 9999;

    @include mac13() {
      font-size: 16px;
    }

    &.box-open {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @include boxesButton(
    $color-wanderer-light-blue,
    $color-wanderer-blue,
    $color-wanderer-light-blue,
    $color-wanderer-blue
  ) {
    display: block;
  }

  .wanderer-box {
    height: 598px;
    left: 0;
    margin: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 325px;
    transition: all 1.8s;
    width: 1005px;
    z-index: 99;

    @include mac15() {
      top: 236px;
    }

    @include mac13() {
      top: 110px;
    }

    &.go-down {
      transform: translateY(60px);

      .sections {
        &:hover {
          .hover {
            z-index: 10;
          }
        }
      }
    }

    .sections {
      .open,
      .hover {
        left: 1px;
        position: absolute;
        top: 1px;
      }

      .hover {
        opacity: 0;
      }

      &:hover {
        .hover {
          opacity: 1;
        }
      }
    }

    .section-1 {
      left: 0;
      position: absolute;
      top: 0;
      z-index: 1;

      .default {
        left: 0;
        position: absolute;
        top: 0;
        transition: all 1.8s;
        z-index: 2;
        &.box-open {
          opacity: 0;
          transform: translateX(-300px);
        }
      }
    }

    .section-2 {
      left: 0;
      position: absolute;
      top: 0;

      .default {
        left: 0;
        position: absolute;
        top: 0;
        transition: all 1.8s;
        z-index: 2;
        &.box-open {
          opacity: 0;
          transform: translate(-300px, -300px);
        }
      }
    }

    .section-3 {
      left: 264px;
      position: absolute;
      top: 0;

      .default {
        left: 0;
        position: absolute;
        top: 0;
        transition: all 1.8s;
        z-index: 2;
        &.box-open {
          opacity: 0;
          transform: translateY(-300px);
        }
      }
    }

    .section-4 {
      left: 324px;
      position: absolute;
      top: 199px;

      .default {
        left: 0;
        position: absolute;
        top: 0;
        transition: all 1.8s;
        z-index: 2;
        &.box-open {
          opacity: 0;
          transform: translateY(200px);

          @include mac13() {
            transform: translateY(100px);
          }

          @include mac15() {
            transform: translateY(150px);
          }
        }
      }
    }

    .section-5 {
      left: 504px;
      position: absolute;
      top: 0;

      .default {
        left: 0;
        position: absolute;
        top: 0;
        transition: all 1.8s;
        z-index: 2;
        &.box-open {
          opacity: 0;
          transform: translate(300px, -300px);
        }
      }
    }

    .section-6 {
      left: 681px;
      position: absolute;
      top: 0;

      .default {
        left: 0;
        position: absolute;
        top: 0;
        transition: all 1.8s;
        z-index: 2;
        &.box-open {
          opacity: 0;
          transform: translateX(300px);
        }
      }
    }
  }

  .thing-1 {
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 60vw;

    img {
      height: 100%;
      transition: all 1.8s;
      width: 100%;
      &.go-down {
        transform: translateY(60px);
        transition: all 1.8s;
      }
    }
  }
  .thing-2 {
    bottom: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 55vw;

    img {
      height: 100%;
      transition: all 1.8s;
      width: 100%;
      &.go-right {
        transform: translateX(100px);
        transition: all 1.8s;
      }
    }
  }
  .thing-3 {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 60vw;

    img {
      height: 100%;
      transition: all 1.8s;
      width: 100%;
      &.go-left {
        transform: translateX(-100px);
        transition: all 1.8s;
      }
    }
  }

  .logo-container {
    bottom: 11%;
    position: absolute;
    text-align: center;
    transition: all 1.8s;

    img {
      transition: all 1.8s;
      width: 175px;
    }

    @include mac13() {
      bottom: 11%;
    }
  }

  &.open {
    .logo-container {
      bottom: 6%;

      @include mac13() {
        bottom: 6%;
      }
    }
  }
}

.wanderer-background .wanderer-box {
  transform: scale(1);
  transition: transform 0.5s;

  @media (max-height: 900px) {
    transform: scale(0.6);
    transition: transform 0.5s;

    &.go-down {
      transform: scale(0.6) translateY(60px);
      transition: transform 0.5s;
    }
  }

  @media (max-width: 900px) {
    transform: scale(0.6);
    transition: transform 0.5s;

    &.go-down {
      transform: scale(0.6) translateY(60px);
      transition: transform 0.5s;
    }
  }

  @media (min-height: 901px) and (max-height: 1100px) {
    top: 225px;
    transform: scale(0.8);
    transition: transform 0.5s;

    &.go-down {
      transform: scale(0.8) translateY(60px);
      transition: transform 0.5s;
    }
  }
}

#popup-root {
  .modal-wanderer {
    color: $color-white;
    margin: auto;
    position: relative;

    .title {
      font-size: 72px;
      font-style: normal;
      font-weight: normal;
      left: 50%;
      line-height: 70px;
      max-width: 439px;
      position: absolute;
      text-align: center;
      top: 10px;
      transform: translateX(-50%);

      h2 {
        font-size: inherit;
      }

      @include mac13() {
        font-size: 52px;
      }

      @include mac15() {
        font-size: 62px;
      }
    }

    .content {
      left: 50%;
      max-width: 500px;
      position: absolute;
      transform: translateX(-50%);

      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .fortune-image {
      background-color: #2454ff;
      height: 338px;
      position: absolute;
      right: -80px;
      top: 100px;
      width: 300px;

      .thumbnail {
        height: auto;
        margin-left: -19px;
        margin-top: 19px;
        width: 300px;
      }
    }

    .footer {
      bottom: 25px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);

      .modal-link {
        @include btnDefault($color-wanderer-light-blue, $color-wanderer-blue);
        margin: 0 auto;
      }

      .download-link {
        color: $color-white;
      }
    }

    &.popup1 {
      max-width: 1303px;

      .title {
        color: #de2859;
      }

      .content {
        top: 150px;
      }
    }

    &.popup2 {
      max-width: 1500px;

      .title {
        color: #c507d6;
      }

      .content {
        top: 200px;
      }
    }

    &.popup3 {
      max-width: 1474px;

      .title {
        color: #00e8f6;
      }

      .content {
        top: 200px;
      }

      .footer {
        width: 68%;
        @include mac13() {
          bottom: 10px;
        }

        .audio-container {
          span {
            color: #0000cb;
            display: block;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            text-align: center;
            @include mac13() {
              font-size: 12px;
            }
          }

          .rhap_container {
            background-color: $color-white;
            border-radius: 50px;

            .rhap_controls-section {
              flex: initial;
            }

            .rhap_main-controls-button {
              color: #0000cb;
              font-size: 25px;
              height: 25px;
              width: 25px;
            }
            .rhap_progress-indicator {
              background: #0000cb;
            }
          }
        }
      }
    }

    &.popup4 {
      max-width: 1238px;

      .title {
        color: #2454ff;
        left: 57%;
      }

      .content {
        left: 57%;
        text-align: center;
        top: 250px;
      }
    }

    &.popup5 {
      max-width: 1648px;

      .title {
        color: #70e65d;
      }

      .content {
        top: 250px;

        @include mac13() {
          top: 190px;
        }

        @include mac15() {
          top: 240px;
        }
      }
    }

    &.popup6 {
      max-width: 1004px;

      .title {
        color: #ffdd00;
      }

      .content {
        top: 250px;
      }
    }

    > .close {
      border: none;
      border-radius: 50px;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      display: block;
      left: 50%;
      padding: 0;
      position: absolute;
      top: -15%;
      transform: translateX(-50%);
    }
  }
}
