.chillaxer-background {
  background-image: url('../../assets/images/boxes/chillaxer/chillaxer-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 750px;

  @include boxesButton($color-white, $color-chillaxer-red, $color-white, $color-chillaxer-red);

  .palm-shadow {
    background-image: url('../../assets/images/boxes/chillaxer/palm-shadow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;

    &.remove {
      opacity: 0;
    }
  }

  .box {
    background-image: url('../../assets/images/boxes/chillaxer/box.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 739px;
    left: -86px;
    margin: auto;
    position: absolute;
    right: 0;
    top: -17px;
    width: 993px;
    z-index: 2;
  }

  .leaves-01 {
    animation: leaves-01-animation 2s ease-in-out 1.5s 1 normal forwards;
    background-image: url('../../assets/images/boxes/chillaxer/leaves-01.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 397px;
    left: -657px;
    margin: auto;
    position: absolute;
    right: 0;
    top: -456px;
    width: 425px;
    z-index: 2;
  }

  .leaves-02 {
    animation: leaves-02-animation 2s ease-in-out 1.5s 1 normal forwards;
    background-image: url('../../assets/images/boxes/chillaxer/leaves-02.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 418px;
    left: 0;
    margin: auto;
    position: absolute;
    right: -703px;
    top: 360px;
    width: 412px;
    z-index: 2;
  }

  .leaves-03 {
    animation: leaves-03-animation 2s ease-in-out 1.5s 1 normal forwards;
    background-image: url('../../assets/images/boxes/chillaxer/leaves-03.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 193px;
    left: 0;
    margin: auto;
    position: absolute;
    right: -703px;
    top: -85px;
    width: 481px;
    z-index: 2;
  }

  .thing-01 {
    animation: things-01-animation 2s ease-in-out 1.5s 1 normal forwards;
    background-image: url('../../assets/images/boxes/chillaxer/thing-01.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 138px;
    left: -940px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 164px;
    width: 138px;
    z-index: 2;
  }

  .thing-02 {
    animation: things-02-animation 2s ease-in-out 1.5s 1 normal forwards;
    background-image: url('../../assets/images/boxes/chillaxer/thing-02.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 364px;
    left: -182px;
    margin: auto;
    position: absolute;
    right: 0;
    top: -515px;
    width: 415px;
    z-index: 2;
  }

  .thing-03 {
    animation: things-03-animation 2s ease-in-out 1.5s 1 normal forwards;
    background-image: url('../../assets/images/boxes/chillaxer/thing-03.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 221px;
    left: -812px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 473px;
    width: 232px;
    z-index: 2;
  }

  .logo {
    animation: click-to-open 2s ease-in-out 1.5s 1 normal forwards;
    bottom: -890px;
    height: 95px;
    left: 0;
    margin: auto;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 194px;
    z-index: 2;

    @include mac13() {
      top: -80px;
    }
  }

  .button-open {
    animation: click-to-open 2s ease-in-out 1.5s 1 normal forwards;
    bottom: -35%;
    color: $color-white;
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: bold;
    height: 30px;
    left: 0;
    letter-spacing: 0.05em;
    line-height: 24px;
    margin: auto;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: auto;
    z-index: 5;
  }

  .box-shadow {
    background-image: url('../../assets/images/boxes/chillaxer/box-shadow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 739px;
    left: 10px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 128px;
    width: 993px;
    z-index: 0;
  }

  .open-box {
    background-image: url('../../assets/images/boxes/chillaxer/background-open-box.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    opacity: 0;
    position: absolute;
    transition: all 1s;
    visibility: hidden;

    &.active {
      opacity: 1;
      transition: all 1s;
      visibility: visible;
      z-index: 6;
    }

    .static-bg {
      bottom: 0;
      height: 808px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;

      width: 1436px;

      .img-bg {
        width: 100%;
      }

      .hover1,
      .hover2,
      .hover3,
      .hover4,
      .hover5,
      .hover6 {
        opacity: 0;
        position: absolute;
        transition: opacity 1s;

        &:hover {
          opacity: 1;
          transition: opacity 1s;
        }
      }

      .hover1 {
        left: 190px;
        top: 351px;
      }

      .hover2 {
        left: 688px;
        top: 215px;
      }

      .hover3 {
        left: 461px;
        top: 610px;
      }

      .hover4 {
        left: 1087px;
        top: 340px;
      }

      .hover5 {
        left: 108px;
        top: 684px;
      }

      .hover6 {
        left: 1158px;
        top: 621px;
      }
    }
  }

  @keyframes leaves-01-animation {
    0% {
      left: -657px;
      top: -456px;
    }
    100% {
      left: -727px;
      top: -507px;
      transform: rotate(19deg);
    }
  }

  @keyframes leaves-02-animation {
    0% {
      right: -703px;
      top: 367px;
    }
    100% {
      right: -823px;
      top: 396px;
      transform: rotate(-19deg);
    }
  }

  @keyframes leaves-03-animation {
    0% {
      right: -703px;
      top: -85px;
    }
    100% {
      right: -1076px;
      top: -43px;
    }
  }

  @keyframes things-01-animation {
    0% {
      left: -940px;
      top: 164px;
    }
    100% {
      left: -980px;
      top: 187px;
    }
  }

  @keyframes things-02-animation {
    0% {
      left: -182px;
      top: -515px;
    }
    100% {
      left: -219px;
      top: -658px;
      transform: rotate(-25deg);
    }
  }

  @keyframes things-03-animation {
    0% {
      left: -812px;
      top: 473px;
    }
    100% {
      left: -926px;
      top: 460px;
    }
  }

  @keyframes click-to-open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.static-bg,
.elements,
#popup-root .popup-content .modal-box {
  height: 100%;
  transform: scale(1);
  transition: transform 0.5s;

  @media (max-height: 800px) {
    transform: scale(0.7);
    transition: transform 0.5s;
  }

  @media (max-width: 800px) {
    transform: scale(0.7);
    transition: transform 0.5s;
  }

  @media (max-height: 900px) {
    transform: scale(0.8);
    transition: transform 0.5s;
  }

  @media (max-width: 900px) {
    transform: scale(0.8);
    transition: transform 0.5s;
  }
}

#popup-root {
  .popup-overlay {
    background: transparent;
  }

  .popup-content {
    animation: anvil 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    background: transparent;
    border: none;
    margin: 0 auto;
    padding: 0;
    width: auto;

    .modal-box {
      font-size: 12px;
      margin: 0 auto;
      max-width: 1436px;

      .content {
        color: $color-white;
        position: absolute;
      }

      .popup-bg {
        height: 100%;
        margin: 0 22px 22px;
        width: calc(100% - 44px);

        @include responsiveBetween('lg', 'xxl') {
          width: inherit;
        }
      }

      .modal-link {
        @include btnDefault($color-white, $color-chillaxer-red);
      }

      .fortune-image {
        background-color: transparent;
        bottom: 112px;
        height: 300px;
        position: absolute;
        right: 360px;
        width: 300px;

        img {
          border: 5px solid #60634d;
          height: 280px;
          margin: 10px;
          width: 280px;
        }
      }

      > .header {
        border-bottom: 1px solid gray;
        font-size: 18px;
        padding: 5px;
        text-align: center;
        width: 100%;
      }

      > .content {
        font-size: 22px;
        line-height: 27px;
        padding: 10px 5px;
      }

      > .close {
        background: #ffffff;
        border: 1px solid #cfcece;
        border-radius: 50px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        display: block;
        font-size: 24px;
        line-height: 20px;
        padding: 2px 5px;
        position: absolute;
        right: -10px;
        top: -10px;
      }

      .audio-container {
        span {
          display: block;
          font-size: 22px;
          font-weight: bold;
          line-height: 27px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        .rhap_container {
          background-color: $color-chillaxer-red;
          border-radius: 50px;

          .rhap_main-controls-button {
            color: $color-white;
          }
          .rhap_progress-indicator {
            background: $color-white;
          }
        }
      }

      &.popup1 {
        .popup-bg {
          margin-left: -78px;
          margin-top: 20px;
          width: 104%;

          @include responsiveBetween('lg', 'xxl') {
            margin-top: 0;
            width: inherit;
          }
        }

        > .content {
          left: 400px;
          top: 327px;
          width: 722px;
        }
      }

      &.popup2 {
        > .content {
          left: 400px;
          top: 327px;
          width: 722px;
        }
      }

      &.popup3 {
        .popup-bg {
          margin-left: -48px;
          width: 102%;

          @include responsiveBetween('lg', 'xxl') {
            width: inherit;
          }
        }

        > .content {
          left: 400px;
          top: 327px;
          width: 722px;
        }
      }

      &.popup4 {
        .content {
          color: $color-black;
          text-align: center;
        }

        > .content {
          left: 360px;
          top: 327px;
          width: 722px;
        }
      }

      &.popup5 {
        .content {
          color: $color-black;
        }

        > .content {
          left: 386px;
          top: 285px;
          width: 722px;
        }
      }

      &.popup6 {
        > .content {
          left: 120px;
          top: 327px;
          width: 400px;
        }
      }
    }
  }

  @keyframes anvil {
    0% {
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
      opacity: 0;
      transform: scale(1) translateY(0px);
    }
    1% {
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
      opacity: 0;
      transform: scale(0.96) translateY(10px);
    }
    100% {
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
      opacity: 1;
      transform: scale(1) translateY(0px);
    }
  }
}
