.entertainer {
  align-items: center;
  background: url('../../assets/images/boxes/entertainer/entertainer-bg.jpg') no-repeat center;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;

  &.hover {
    background: url('../../assets/images/boxes/entertainer/entertainer-hover-bg.jpg') no-repeat
      center;
    background-size: cover;

    .stars-container {
      display: inline-block;
    }
  }

  &.open {
    background: url('../../assets/images/boxes/entertainer/open-box-bg.jpg') no-repeat center;
    background-size: cover;

    // Hover states
    &.sharing {
      background: url('../../assets/images/boxes/entertainer/sharing-hover.jpg') no-repeat center;
      background-size: cover;
    }
    &.playlist {
      background: url('../../assets/images/boxes/entertainer/playlist-hover.jpg') no-repeat center;
      background-size: cover;
    }
    &.hobbies {
      background: url('../../assets/images/boxes/entertainer/hobbies-hover.jpg') no-repeat center;
      background-size: cover;
    }
    &.meditation {
      background: url('../../assets/images/boxes/entertainer/meditation-hover.jpg') no-repeat center;
      background-size: cover;
    }
    &.recipes {
      background: url('../../assets/images/boxes/entertainer/recipes-hover.jpg') no-repeat center;
      background-size: cover;
    }
    &.fortune {
      background: url('../../assets/images/boxes/entertainer/fortune-cookie-hover.jpg') no-repeat
        center;
      background-size: cover;
    }

    // Popup states
    &.sharing-popup {
      background: url('../../assets/images/boxes/entertainer/sharing-popup.jpg') no-repeat center;
      background-size: cover;

      .sharing-content {
        display: block;
      }
    }

    &.playlist-popup {
      background: url('../../assets/images/boxes/entertainer/playlist-popup.jpg') no-repeat center;
      background-size: cover;

      .playlist-content {
        display: block;
      }
    }

    &.hobbies-popup {
      background: url('../../assets/images/boxes/entertainer/hobbies-popup.jpg') no-repeat center;
      background-size: cover;

      .hobbies-content {
        display: block;
      }
    }

    &.meditation-popup {
      background: url('../../assets/images/boxes/entertainer/meditation-popup.jpg') no-repeat center;
      background-size: cover;

      .meditation-content {
        display: block;
      }
    }

    &.recipes-popup {
      background: url('../../assets/images/boxes/entertainer/recipes-popup.jpg') no-repeat center;
      background-size: cover;

      .recipes-content {
        display: block;
      }
    }

    &.fortune-popup {
      background: url('../../assets/images/boxes/entertainer/fortune-cookie-popup.jpg') no-repeat
        center;
      background-size: cover;

      .fortune-content {
        display: block;
      }
    }

    .other-types-button {
      display: block;
    }

    .logo-container {
      display: block;
    }

    .boxes-container {
      display: block;
    }
  }

  &.popup-open {
    .clickable-box {
      cursor: default;
    }
    .boxes-container {
      display: none;
    }
    .popup-close {
      display: block;
      z-index: 1;
    }
  }

  .clickable-box {
    cursor: pointer;
    height: 55vh;
    margin-top: 9vh;
    position: relative;
    width: 47vw;

    @include mac13() {
      height: 55vh;
      margin-top: 9vh;
      width: 47vw;
    }

    @include responsiveBetween('sm', 'xxl') {
      width: 85vh;
    }
  }

  .stars-container {
    display: none;
    height: 100%;
    position: relative;
    width: 100%;
  }

  .boxes-container {
    display: none;
    height: 100%;
    width: 100%;

    > div {
      height: 50%;
    }
  }

  @include boxesButton(
    $color-entertainer-yellow,
    $color-entertainer-blue,
    $color-entertainer-orange,
    $color-entertainer-blue
  ) {
    display: none;
  }

  .logo-container {
    display: none;
    position: absolute;
    right: 4%;
    bottom: 4%;
  }

  .popup-close {
    cursor: pointer;
    display: none;
    position: absolute;
    right: 0;
    top: -8%;
    width: 65px;

    img {
      width: 100%;
    }

    .hover {
      display: none;
    }

    &:hover {
      .hover {
        display: block;
      }
      .default {
        display: none;
      }
    }

    @include mac13() {
      right: 0;
      top: -8%;
    }

    @include responsiveBetween('sm', 'xxl') {
      right: 1%;
      top: -5%;
    }
  }

  .popup-container {
    display: none;
    padding-left: 60px;
    padding-top: 320px;
    position: absolute;

    @include mac15() {
      padding-top: 23vh;
    }

    @include mac13() {
      padding-top: 23vh;
    }

    .popup-text {
      color: $color-white;
      // font-size: 20px;
      font-size: 14px;
      font-weight: 600;

      @include responsiveBetween('sm', 'xxl') {
        font-size: 14px;
      }

      @include responsiveBetween('sm', 'lg') {
        font-size: 12px;
      }
    }

    .download-link a {
      color: $color-white;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;

      @include mac13() {
        font-size: 13px;
      }

      @include responsiveBetween('sm', 'xxl') {
        font-size: 13px;
      }
    }

    .btn {
      background-color: $color-entertainer-yellow;
      border: none;
      border-radius: 100px;
      color: $color-entertainer-blue;
      font-size: 18px;
      font-weight: 700;
      margin-top: 10px;
      padding: 8px 0;
      text-decoration: none;
      text-transform: uppercase;
      width: 20%;

      @include mac13() {
        font-size: 16px;
        padding: 5px 0;
        width: 18%;
      }

      @include responsiveBetween('sm', 'xxl') {
        font-size: 16px;
        padding: 5px 0;
        width: 18%;
      }

      &:hover {
        background-color: $color-entertainer-orange;
      }
    }
  }

  .sharing-content {
    .popup-text {
      width: 65%;
    }
  }

  .playlist-content {
    .popup-text {
      width: 60%;
    }

    .btn {
      margin-top: 15px;
    }
  }

  .hobbies-content {
    padding-top: 360px;
    @include mac15() {
      padding-top: 245px;
    }

    @include mac13() {
      padding-top: 185px;
    }

    .popup-text {
      width: 60%;
    }

    .btn {
      width: 40%;
    }
  }

  .meditation-content {
    padding-top: 210px;

    @include mac15() {
      padding-top: 140px;
    }

    @include mac13() {
      padding-top: 110px;
    }

    .popup-text {
      width: 75%;
    }
  }

  .recipes-content {
    padding-top: 290px;

    @include mac15() {
      padding-top: 200px;
    }

    @include mac13() {
      padding-top: 160px;
    }

    .popup-text {
      width: 70%;
    }
    .btn {
      width: 35%;
    }
  }

  .fortune-content {
    height: 100%;
    padding-top: 400px;
    position: relative;
    width: 100%;

    @include mac15() {
      padding-top: 280px;
    }

    @include mac13() {
      padding-top: 195px;
    }

    .popup-text {
      width: 45%;
    }

    .fortune-image {
      position: absolute;
      right: 3%;
      top: 21%;
      width: 45%;

      @include mac13() {
        right: 3%;
        top: 18%;
      }

      @include responsiveBetween('sm', 'xxl') {
        right: 8%;
        top: 17%;
        width: 40%;
      }

      .thumbnail {
        margin-top: 0%;
        width: 100%;

        @include mac13() {
          margin-top: -8%;
        }

        @include responsiveBetween('sm', 'xxl') {
          margin-top: 0;
        }
      }

      img {
        float: right;
      }

      .frame {
        position: absolute;
        top: -23%; // UPDATE FOR BIGGER THAN 13
        width: 100%;

        @include mac13() {
          top: -23%;
        }

        @include responsiveBetween('sm', 'xxl') {
          top: -13%;
        }
      }

      .thumbnail-img {
        display: inherit;
        float: unset;
        margin: 0 auto;
        margin-top: 2%; // UPDATE FOR BIGGER THAN 13
        width: 85%;

        @include mac13() {
          margin-top: 10%;
        }

        @include responsiveBetween('sm', 'xxl') {
        }
      }
    }

    .btn {
      bottom: 10%; // UPDATE FOR BIGGER THAN 13
      position: absolute;
      right: 7.5%; // UPDATE FOR BIGGER THAN 13
      width: 35%;

      @include mac13() {
        bottom: 10%;
        right: 7.5%;
      }

      @include responsiveBetween('sm', 'xxl') {
        bottom: 15%;
        right: 10%;
      }

      img {
        margin-right: 5px;
        width: 17px;
      }
    }
  }

  .audio-container {
    margin: 0;
    width: 70%;
    span {
      color: $color-white;
      font-size: 12px;
      font-weight: 500;
    }

    .rhap_container {
      background-color: $color-entertainer-dark-blue;
      border-radius: 50px;
      padding: 6px 15px;

      .rhap_main-controls-button {
        color: $color-entertainer-yellow;
        &:hover {
          color: $color-entertainer-orange;
        }
      }
      .rhap_progress-indicator {
        background: $color-entertainer-yellow;
        height: 8px;
        margin-left: 0;
        top: -3px;
        width: 8px;
        &:hover {
          background: $color-entertainer-orange;
        }
      }

      .rhap_progress-filled {
        background-color: $color-entertainer-yellow;
      }

      .rhap_play-pause-button {
        font-size: 22px;
        height: 22px;
        width: 22px;
      }

      .rhap_rewind-button,
      .rhap_forward-button {
        display: none;
      }

      .rhap_progress-bar {
        height: 2px;
      }

      .rhap_controls-section {
        flex: initial;
      }
    }
  }

  .star {
    position: absolute;
  }

  .starOne {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
    bottom: 11%;
    right: 31%;

    @include mac13() {
      bottom: 11%;
      right: 31%;
    }

    @include responsiveBetween('sm', 'xxl') {
      bottom: 15%;
      right: 32%;
    }
  }
  .starTwo {
    animation: blink-animation 2.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 2.5s steps(5, start) infinite;
    bottom: 18%;
    right: 35%;

    @include mac13() {
      bottom: 18%;
      right: 35%;
    }

    @include responsiveBetween('sm', 'xxl') {
      bottom: 21%;
      right: 36%;
    }
  }
  .starThree {
    animation: blink-animation 1.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
    bottom: 5%;
    right: 34%;

    @include mac13() {
      bottom: 5%;
      right: 34%;
    }

    @include responsiveBetween('sm', 'xxl') {
      bottom: 9%;
      right: 35%;
    }
  }
}

.preloadImage {
  visibility: hidden;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
