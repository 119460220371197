.assessment {
  height: 100vh;

  &.changing {
    .content {
      opacity: 0;
      transition: opacity 0.5s;
    }

    .assessment-background {
      opacity: 0;
      transition: all 0.5s ease;
    }
  }

  .overlay {
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .assessment-background {
    float: left;
    height: 100%;
    left: 0;
    object-fit: cover;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all 0.5s ease;
    width: 100%;
    z-index: -1;

    &.active {
      opacity: 1;
    }
  }

  .content {
    max-width: 1010px;
    opacity: 1;
    transition: opacity 0.5s;
    z-index: 1;

    .question-section {
      background: $color-white;
      border: 3px solid $color-white;
      box-sizing: border-box;
      margin-bottom: 30px;
      position: relative;
      width: 100%;

      .question-text {
        color: $color-grey;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        padding: 30px;

        @include mac13() {
          font-size: 22px;
          line-height: 28px;
          padding: 20px;
        }

        @include responsiveBetween('sm', 'xl') {
          padding: 15px;
        }

        @media screen and (max-width: 1280px), screen and (max-height: 610px) {
          padding: 15px;
        }
      }
    }

    .answer-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      button {
        background: rgba(255, 255, 255, 0.2);
        border: 3px solid $color-white;
        box-sizing: border-box;
        color: $color-white;
        cursor: pointer;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        height: 150px;
        line-height: 40px;
        margin: 10px auto;
        text-align: center;
        transition: all 0.5s;
        width: 100%;

        @include mac13() {
          font-size: 30px;
          line-height: 30px;
          height: 115px;
        }

        @include responsiveBetween('sm', 'xl') {
          height: 90px;
        }

        @media screen and (max-width: 1280px), screen and (max-height: 610px) {
          height: 90px;
        }

        &:hover {
          border: 16px solid $color-white;
          transition: all 0.5s;

          @media screen and (max-width: 1280px), screen and (max-height: 610px) {
            border: 12px solid $color-white;
          }
        }
      }
    }
  }

  .progress-bullets {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 110px;
    z-index: 1;

    @include mac13() {
      padding-top: 35px;
    }

    @include responsiveBetween('sm', 'xl') {
      padding-top: 30px;
    }

    @media screen and (max-width: 1280px), screen and (max-height: 610px) {
      padding-top: 30px;
    }

    .bullet {
      background: $color-white;
      border-radius: 50%;
      color: $color-black;
      font-size: 18px;
      height: 30px;
      width: 30px;

      @include mac13() {
        height: 20px;
        width: 20px;
      }

      @media screen and (max-width: 1280px), screen and (max-height: 610px) {
        height: 18px;
        width: 18px;
      }

      &:not(:last-child) {
        margin-right: 55px;

        @include mac13() {
          margin-right: 35px;
        }

        @media screen and (max-width: 1280px), screen and (max-height: 610px) {
          margin-right: 25px;
        }
      }

      &.active {
        height: 55px;
        width: 55px;

        @include mac13() {
          height: 35px;
          width: 35px;
        }

        @media screen and (max-width: 1280px), screen and (max-height: 610px) {
          height: 25px;
          width: 25px;
        }
      }

      &.visited {
        background: url('../assets/images/questions/spot-visited.png');
        background-size: 100%;
      }
    }
  }

  .prueba {
    bottom: 100px;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    @include mac13() {
      bottom: 30px;
    }

    button {
      background-color: transparent;
      border: 2px solid $color-white;
      border-radius: 50px;
      border-radius: 100px;
      border-style: solid;
      box-sizing: border-box;
      color: $color-white;
      font-size: 18px;
      letter-spacing: 0.05em;
      line-height: 22px;
      padding: 10px 28px;
      text-decoration: none;
      text-transform: uppercase;

      @include mac13() {
        font-size: 14px;
        padding: 7px 24px;
      }
    }
  }
}
