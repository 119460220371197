.adventurer {
  background-image: url('../../assets/images/boxes/adventurer/box-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  transition: all 0.5s;

  &.open-box {
    background-image: url('../../assets/images/boxes/adventurer/box-bg-open.jpg');
  }

  @include boxesButton(
    $color-adventurer-orange,
    $color-white,
    $color-adventurer-orange,
    $color-white
  ) {
    display: block;
  }

  .leaves {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }

  .animation-box {
    position: absolute;
    width: 100%;
    height: 100vh;
    img {
      height: 100%;
    }
  }

  .leaves-top {
    top: 0;
    right: 0;
    animation: topOut 2s ease-in-out 0.5s 1 normal forwards;
    &.open-box {
      animation: topOutExtended 2s ease-in-out 0s 1 normal forwards;
    }
  }

  .leaves-static {
    top: 0;
    right: 0;
    img {
      width: 100%;
    }
    &.open-box {
      animation: staticZoomOut 2s ease-in-out 0s 1 normal forwards;
    }
  }

  .leaves-left {
    bottom: 0;
    left: 0;
    animation: leftOut 2s ease-in-out 1.5s 1 normal forwards;
    &.open-box {
      animation: leftOutExtended 2s ease-in-out 0s 1 normal forwards;
    }
  }

  .leaves-right {
    bottom: 0;
    right: 0;
    animation: rightOut 2s ease-in-out 1s 1 normal forwards;
    img {
      float: right;
    }
    &.open-box {
      animation: rightOutExtended 2s ease-in-out 0s 1 normal forwards;
    }
  }

  .content {
    .intro {
      position: absolute;
      width: 35vw;
      height: 29vh;
      top: 80px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      animation: zoomOutFadeIn 1s ease-in-out 2.5s 1 normal forwards;
      opacity: 0;
      .intro-text {
        width: 100%;
      }
      .intro-btn {
        width: 50%;
        height: 35%;
        background-image: url('../../assets/images/boxes/adventurer/intro-btn.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        transition: 0.5s all;
        margin: auto;
        display: block;
        margin-top: 30px;
        border: none;
        &:hover {
          background-image: url('../../assets/images/boxes/adventurer/intro-btn-hover.svg');
        }
      }
    }

    .box {
      position: absolute;
      width: 64vw;
      height: 100vh;
      padding: 28vh 10vw;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-image: url('../../assets/images/boxes/adventurer/box.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      animation: fadeIn 1s ease-in-out 1s 1 normal forwards;
      .items {
        position: relative;
        height: 100%;
      }
      .item {
        position: absolute;
        transition: all 1s;
        img {
          width: 100%;
        }

        .label {
          opacity: 0;
          transition: opacity 1s;
          position: absolute;
          left: 0;
          width: 18vw;
        }

        &.hover {
          opacity: 0.45;
        }

        &.main-hover {
          z-index: 99;
          transform: scale(1.1);
          .label {
            opacity: 1;
          }
        }
      }
      .recipes {
        top: 0;
        left: 0;
        width: 20vw;
        .label {
          top: 30%;
        }
      }
      .candy {
        top: -30px;
        left: 190px;
        right: 0;
        margin: auto;
        width: 22vw;
        .label {
          top: 80%;
        }
      }

      .cookie {
        top: 0;
        right: 30px;
        width: 9vw;
        .label {
          top: 80%;
          left: -60%;
          z-index: -1;
        }
      }

      .playlist {
        width: 20vw;
        bottom: 0;
        left: 0;
        .label {
          top: 30%;
        }
      }

      .interest {
        width: 17vw;
        bottom: 0;
        left: 30px;
        right: 0;
        margin: auto;
        .label {
          top: 40%;
        }
      }

      .meditation {
        width: 20vw;
        bottom: 0;
        right: -30px;
        .label {
          top: 50%;
        }
      }
    }
  }
}

//popups
.adventurer-popup-content {
  position: absolute !important;
  .adventurer-popup {
    font-family: 'Underdog', cursive;
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/boxes/adventurer/popups/popup-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .popup-content {
      max-width: 100% !important;
      height: 100%;
    }
    .close {
      z-index: 999;
      position: absolute;
      top: 50px;
      right: 0;
      width: 97px;
      height: 89px;
      background-color: transparent;
      border: none;
      background-image: url('../../assets/images/boxes/adventurer/popups/x.svg');
      &:hover {
        background-image: url('../../assets/images/boxes/adventurer/popups/x-hover.svg');
      }
    }

    .popup-text {
      text-align: center;
      .title {
        font-size: 50px;
        font-style: italic;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;

        @include mac13() {
          font-size: 42px;
        }
      }
      .body {
        font-size: 25px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 40px;

        @include mac13() {
          font-size: 22px;
          line-height: 30px;
        }
      }

      .audio-container {
        span {
          display: block;
          font-size: 22px;
          font-weight: bold;
          line-height: 27px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        .rhap_container {
          background-image: url('../../assets/images/boxes/adventurer/popups/bg-audio.png');
          background-color: transparent;
          background-size: 100% 100%;
          background-position: center;
          box-shadow: none;

          .rhap_main-controls-button {
            color: $color-white;
          }
          .rhap_progress-indicator {
            background: $color-white;
          }
        }
      }

      .link {
        font-family: 'Underdog', cursive;
        font-size: 25px;
        display: inline-block;
        color: #ffffff;
        padding: 20px 80px;
        text-decoration: none;
        text-align: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/images/boxes/adventurer/popups/btn.svg');
        &:hover {
          background-image: url('../../assets/images/boxes/adventurer/popups/btn-hover.svg');
        }
        .pinterest {
          margin-left: 30px;
        }
      }
    }

    &.interest {
      .popup-text {
        width: 30vw;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: fit-content;
        margin: auto;
        transform: translateX(-300px);
        z-index: 99;
      }
      .popup-image {
        width: 56vw;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    &.candy {
      .popup-text {
        width: 40vw;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: fit-content;
        margin: auto;
      }
      .candy-up {
        position: absolute;
        top: 0;
        left: 0;
      }
      .candy-down {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    &.playlist {
      .music-up {
        width: 50vw;
        position: absolute;
        top: 0;
        left: 0;
      }
      .music-down {
        width: 75vw;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .popup-text {
        width: 40vw;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform: translateX(300px);
        height: fit-content;
        margin: auto;
      }
    }

    &.recipes {
      .popup-text {
        background-image: url('../../assets/images/boxes/adventurer/popups/recipes-paper.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 30vh 30vw 30vh 39vw;
        -webkit-appearance: caret;
        width: 100vw;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &.meditation {
      .popup-text {
        width: 34vw;
        position: absolute;
        left: -25vw;
        right: 0;
        top: -6vw;
        bottom: 0;
        height: fit-content;
        margin: auto;
      }
      .meditation-up {
        position: absolute;
        top: 0;
        left: 0;
        width: 42vw;
      }
      .meditation-down {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 55vw;
      }
    }

    &.cookie {
      .popup-text {
        background-image: url('../../assets/images/boxes/adventurer/popups/fortune-cookie-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 90vw;
        padding: 23vh 22vw;
        position: absolute;
        left: 0;
        right: 0;
        top: 0vw;
        bottom: 0;
        height: auto;
        margin: auto;

        @include mac13() {
          padding: 23vh 22vw;
        }

        @include responsiveBetween('sm', 'xxl') {
          width: 100vw;
        }

        .text {
          display: inline-block;
          width: 50%;
          vertical-align: top;
          margin-top: 100px;
        }
        .image {
          display: inline-block;
          width: 50%;
          vertical-align: top;

          .thumbnail {
            background-image: url('../../assets/images/boxes/adventurer/popups/fortune-cookie_instant.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            padding: 50px 65px 140px 50px;

            @include mac13() {
              padding: 50px 90px 140px 50px;
              margin-top: 30px;
            }

            img {
              width: 100%;
            }
          }

          .download-btn {
            position: relative;
            bottom: 90px;
            background-image: url('../../assets/images/boxes/adventurer/popups/btn.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            font-size: 25px;
            text-transform: uppercase;
            padding: 30px 60px;
            color: white;
            &:hover {
              background-image: url('../../assets/images/boxes/adventurer/popups/btn-hover.svg');
            }
          }
        }
      }
    }
  }
}
@keyframes leftOut {
  0% {
    left: 0;
  }
  100% {
    left: -240px;
  }
}

@keyframes leftOutExtended {
  0% {
    left: -240px;
  }
  100% {
    left: -380px;
  }
}

@keyframes rightOut {
  0% {
    right: 0;
  }
  100% {
    right: -210px;
  }
}

@keyframes rightOutExtended {
  0% {
    right: -210px;
  }
  100% {
    right: -480px;
  }
}

@keyframes topOut {
  0% {
    top: 0;
  }
  100% {
    top: -290px;
  }
}

@keyframes topOutExtended {
  0% {
    top: -290px;
  }
  100% {
    top: -410px;
  }
}

@keyframes staticZoomOut {
  0% {
    transform: scale(1);
    top: 0;
  }
  100% {
    top: 40px;
    transform: scale(1.1);
  }
}

@keyframes zoomOutFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
