.type-selector-container {
  height: 100vh;

  .link-container {
    height: 80vh;
  }

  .categories-content {
    height: 80vh;

    &.hover {
      .overlay {
        display: block;
      }
    }

    .content {
      cursor: pointer;
      position: relative;

      &.current-hover .overlay {
        display: none;
      }
    }

    .overlay {
      background-color: $color-black;
      display: none;
      height: 80vh;
      mix-blend-mode: multiply;
      opacity: 0.5;
      position: absolute;
      width: 100%;
    }

    .category {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
    }

    .chillaxer {
      background-image: url('../assets/images/typeSelector/chillaxer.jpg');
    }
    .wanderer {
      background-image: url('../assets/images/typeSelector/wanderer.jpg');
    }
    .entertainer {
      background-image: url('../assets/images/typeSelector/entertainer.jpg');
    }
    .adventurer {
      background-image: url('../assets/images/typeSelector/adventurer.jpg');
    }
  }

  .bottom-content {
    background-image: url('../assets/images/typeSelector/selector-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vh;
  }

  .retake-container {
    .retake-test {
      background-color: $color-victoria;
      border: none;
      border-radius: 100px;
      color: $color-white;
      display: block;
      font-size: 28px;
      font-weight: 700;
      margin: 15px auto;
      padding: 4px 0;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: 25%;

      @include responsiveBetween('sm', 'xxl') {
        font-size: 16px;
      }

      @include mac13() {
        font-size: 16px;
      }
    }
  }
}
