.mobile-detection {
  background-color: $color-manatee;
  height: 100vh;

  .content {
    background-color: $color-white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding-bottom: 20px;
    padding-top: 20px;

    p {
      margin-bottom: 20px;
    }

    .title {
      margin-bottom: 20px;
      margin-top: 40px;
    }
  }
}
