.tablet-portrait-detection {
  align-items: center;
  background-color: $color-manatee;
  display: flex;
  height: 100vh;
  justify-content: center;

  .message-container {
    background-image: url('../assets/images/tablet-popup-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 410px;
  }

  .title {
    width: 250px;
  }

  .small {
    color: $color-grey;
    font-size: 22px;
    font-weight: 700;
    margin-top: 25px;
    padding: 0 64px;
    width: 410px;
  }
}
