.result-container {
  color: $color-white;
  height: 100%;
  min-height: 750px;
  overflow: inherit;

  @include responsiveBetween('sm', 'xxl') {
    height: inherit;
  }

  @include mac13() {
    height: inherit;
  }

  .message-container {
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.bg-chillaxer {
    background: $color-chillaxer-blue;

    .message-container {
      background-image: url('../assets/images/results/chillaxer/bg.jpg');
    }

    .bottom-section .buttons .button-result:hover {
      color: $color-chillaxer-red;
    }

    .bottom-section .buttons .button-result.button-inverted {
      color: $color-chillaxer-red;

      &:hover {
        color: $color-white;
      }
    }

    .social-container {
      color: $color-chillaxer-red;
    }

    .message-container {
      background-color: $color-chillaxer-blue;
    }

    .social-links {
      .fb-link {
        background-image: url('../assets/images/results/chillaxer/fb-icon.svg');
      }
      .tw-link {
        background-image: url('../assets/images/results/chillaxer/tw-icon.svg');
      }
      .linkedin-link {
        background-image: url('../assets/images/results/chillaxer/linkedin-icon.svg');
      }
    }
  }

  &.bg-wanderer {
    background: $color-wanderer-gradient;

    .message-container {
      background-image: url('../assets/images/results/wanderer/bg.jpg');
    }

    .bottom-section .buttons .button-result:hover {
      color: $color-wanderer-blue;
    }

    .bottom-section .buttons .button-result.button-inverted {
      color: $color-wanderer-blue;

      &:hover {
        color: $color-white;
      }
    }

    .social-container {
      color: $color-wanderer-blue;
    }

    .social-links {
      .fb-link {
        background-image: url('../assets/images/results/wanderer/fb-icon.svg');
      }
      .tw-link {
        background-image: url('../assets/images/results/wanderer/tw-icon.svg');
      }
      .linkedin-link {
        background-image: url('../assets/images/results/wanderer/linkedin-icon.svg');
      }
    }
  }

  &.bg-entertainer {
    background: $color-entertainer-bg;

    .message-container {
      background-image: url('../assets/images/results/entertainer/bg.jpg');
    }

    .bottom-section .buttons .button-result {
      border: 2px solid $color-entertainer-yellow;
      color: $color-entertainer-yellow;

      &:hover {
        background-color: $color-entertainer-yellow;
        color: $color-entertainer-blue;
      }

      &.button-inverted {
        background-color: $color-entertainer-yellow;
        color: $color-adventurer-blue;

        &:hover {
          background-color: transparent;
          color: $color-entertainer-yellow;
        }
      }
    }

    .social-container {
      color: $color-entertainer-blue;
    }

    .message-container {
      background-color: $color-entertainer-bg;
    }

    .social-links {
      .fb-link {
        background-image: url('../assets/images/results/entertainer/fb-icon.svg');
      }
      .tw-link {
        background-image: url('../assets/images/results/entertainer/tw-icon.svg');
      }
      .linkedin-link {
        background-image: url('../assets/images/results/entertainer/linkedin-icon.svg');
      }
    }

    .download-link a {
      color: $color-entertainer-yellow;
    }
  }

  &.bg-adventurer {
    background: $color-adventurer-blue;

    .message-container {
      background-image: url('../assets/images/results/adventurer/bg.jpg');
    }

    .bottom-section .buttons .button-result {
      border: 2px solid $color-adventurer-orange;
      color: $color-adventurer-orange;

      &:hover {
        background-color: $color-adventurer-orange;
        color: $color-adventurer-blue;
      }

      &.button-inverted {
        background-color: $color-adventurer-orange;
        color: $color-adventurer-blue;
        &:hover {
          background-color: transparent;
          color: $color-adventurer-orange;
        }
      }
    }

    .social-container {
      color: $color-adventurer-blue;
    }

    .message-container {
      background-color: $color-adventurer-blue;
    }

    .social-links {
      .fb-link {
        background-image: url('../assets/images/results/adventurer/fb-icon.svg');
      }
      .tw-link {
        background-image: url('../assets/images/results/adventurer/tw-icon.svg');
      }
      .linkedin-link {
        background-image: url('../assets/images/results/adventurer/linkedin-icon.svg');
      }
    }

    .download-link a {
      color: $color-adventurer-orange;
    }
  }

  .result-title,
  p {
    font-size: 2.042vmin;
  }

  .result-title {
    font-weight: 700;
    margin-bottom: 25px;
  }

  .bottom-text {
    font-weight: 700;
    margin-top: 40px;
    text-align: center;
  }
  .bottom-section {
    .buttons {
      margin-top: 35px;
      text-align: center;
      .button-result {
        background-color: transparent;
        border: 2px solid $color-white;
        border-radius: 100px;
        color: $color-white;
        display: block;
        font-size: 2.042vmin;
        font-weight: 700;
        margin: 15px auto;
        padding: 4px 0;
        text-decoration: none;
        text-transform: uppercase;
        width: 95%;

        &:hover {
          background-color: $color-white;
        }

        &.button-inverted {
          background-color: $color-white;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  .social-container {
    background-color: $color-white;
    border-radius: 10px;
    font-size: 2.042vmin;
    margin: 20px auto 0;
    padding: 15px 40px;
    text-align: center;
    width: 95%;

    span {
      font-weight: 700;
    }
  }

  .social-links {
    margin-top: 15px;
    a {
      background-size: cover;
      display: inline-block;
      height: 80px;
      margin: 0 15px;
      text-decoration: none;
      vertical-align: text-center;
      width: 80px;

      @include responsiveBetween('sm', 'xxl') {
        height: 40px;
        margin: 0 8px;
        width: 40px;
      }

      @include mac13() {
        height: 40px;
        margin: 0 8px;
        width: 40px;
      }
    }
  }

  .download-link {
    margin: 30px 0 0;
    text-align: center;
    a {
      color: $color-white;
      font-size: 2.042vmin;
      font-weight: 700;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
}
