//
//  RESPONSIVE UP
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include responsiveUp(sm) {}
@mixin responsiveUp($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPONSIVE DOWN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include responsiveDown(sm) {}
@mixin responsiveDown($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPONSIVE BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include responsiveBetween(sm, md) {}
@mixin responsiveBetween($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($grid-breakpoints, $lower);
    $upper-breakpoint: map-get($grid-breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($grid-breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($grid-breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin mac13() {
  @media only screen and (max-width: 1600px) and (max-height: 2560px) {
    @content;
  }
}

@mixin mac15() {
  @media only screen and (max-width: 1800px) and (max-height: 2880px) {
    @content;
  }
}

@mixin boxesButton($backgroundColor, $color, $hoverBackgroundColor, $hoverColor) {
  .other-types-button {
    @content;
    position: absolute;
    background-color: $backgroundColor;
    border: none;
    border-radius: 100px;
    color: $color;
    font-size: 18px;
    font-weight: 700;
    padding: 8px 0;
    text-decoration: none;
    text-transform: uppercase;
    width: 280px;
    top: 30px;
    left: 30px;

    &:hover {
      background-color: $hoverBackgroundColor;
      color: $hoverColor;
    }

    @include responsiveBetween('sm', 'xxl') {
      font-size: 16px;
      padding: 5px 0;
      width: 22%;
    }

    @include mac13() {
      font-size: 16px;
      padding: 5px 0;
      width: 18%;
      top: 15px;
      left: 15px;
    }
  }
}

@mixin btnDefault($backgroundColor, $color) {
  background-color: $backgroundColor;
  border: none;
  border-radius: 100px;
  color: $color;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 26px;
  text-decoration: none;
  text-transform: uppercase;
  width: auto;
  margin-top: 30px;
  display: inline-block;

  @include responsiveBetween('sm', 'xxl') {
    font-size: 16px;
  }

  @include mac13() {
    font-size: 16px;
  }
}
