body {
  font-family: $montserrat-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  font-size: 22px;
  line-height: 1.36;

  &.small {
    font-size: 18px;
    line-height: 22px;
  }
}
