$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$montserrat-font: 'Montserrat', sans-serif;

//For new name colors, please use https://chir.ag/projects/name-that-color/

$color-black: #000000;
$color-chic: #ffcc33;
$color-grey: #768390;
$color-manatee: #959599;
$color-orange: #f5762d;
$color-violet: #594697;
$color-white: #ffffff;
$color-chillaxer-red: #c43111;
$color-chillaxer-blue: #19546a;
$color-wanderer-gradient: linear-gradient(180deg, #0085ff 0%, #3e15b0 100%);
$color-wanderer-blue: #0000cb;
$color-wanderer-light-blue: #07ffff;
$color-entertainer-blue: #505989;
$color-entertainer-dark-blue: #2a3855;
$color-entertainer-bg: #3a4372;
$color-entertainer-yellow: #ebe70e;
$color-entertainer-orange: #e38e65;
$color-adventurer-blue: #022b3e;
$color-adventurer-orange: #f5762d;
$color-victoria: #594697;
