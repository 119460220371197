.App {
  height: 100vh;
}

.spinner-div {
  > div {
    bottom: 0;
    display: block;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
  }
}
