.intro-bg {
  background-image: url('../assets/images/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  .content {
    background-color: white;
    border-radius: 50%;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    color: $color-grey;
    height: 1000px;
    padding: 70px;
    width: 1000px;
    -webkit-font-smoothing: subpixel-antialiased;
    @media (max-height: 1000px) {
      transform: scale(0.9);
    }
    @media (max-height: 910px) {
      transform: scale(0.7);
    }

    p {
      margin-bottom: 15px;

      span {
        display: block;
      }

      @include responsiveBetween('lg', 'xxl') {
        font-size: 16px;
      }
    }

    .logo {
      margin-bottom: 40px;
    }

    .title {
      margin-bottom: 25px;

      @include responsiveBetween('lg', 'xxl') {
        max-width: 300px;
      }
    }

    .main-text {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 25px;

      @include responsiveBetween('lg', 'xxl') {
        font-size: 30px;
      }
    }

    .disclaimer {
      font-size: 12px;
      padding: 0 140px;
      margin-top: 0px;

      @include responsiveBetween('lg', 'xxl') {
        font-size: 10px;
      }
    }

    .disclaimer-mini {
      font-size: 12px;
      padding: 0 140px;
    }

    a.link,
    button {
      background-color: $color-violet;
      border-color: $color-violet;
      border-radius: 50px;
      border-style: solid;
      color: $color-white;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0.05em;
      line-height: 34px;
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 19px 48px;
      text-decoration: none;
      text-transform: uppercase;

      @include responsiveBetween('lg', 'xxl') {
        font-size: 24px;
        margin-top: 0;
        padding: 5px 35px;
      }
    }

    @include responsiveBetween('lg', 'xxl') {
      height: 750px;
      padding: 50px;
      width: 750px;
    }
  }

  .disclaimer-devices {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 20px;
    width: 35%;
    font-size: 16px;
    line-height: 18px;
    color: $color-white;

    @include mac13() {
      font-size: 14px;
      line-height: 16px;
    }

    @include responsiveBetween('lg', 'xxl') {
      font-size: 10px;
      line-height: 12px;
    }

    img {
      margin-right: 8px;
      width: 35px;

      @include mac13() {
        margin-right: 6px;
        width: 30px;
      }

      @include responsiveBetween('lg', 'xxl') {
        margin-right: 5px;
        width: 25px;
      }
    }
  }
}
